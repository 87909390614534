import { render, staticRenderFns } from "./MobileNavigationItem.vue?vue&type=template&id=62e3c2f2&"
import script from "./MobileNavigationItem.vue?vue&type=script&lang=ts&"
export * from "./MobileNavigationItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationLink: require('/tmp/build_9faa0235/src/components/navigation/NavigationLink.vue').default,Button: require('/tmp/build_9faa0235/src/components/button/Button.vue').default,AnimateExpand: require('/tmp/build_9faa0235/src/components/animate/AnimateExpand.vue').default})
